export const toast = {
  show: (txt) => {
    const $toast = document.getElementById('toast');
    $toast.style.display = 'block';
    $toast.innerText = txt;
  },
  hide: () => {
    const $toast = document.getElementById('toast');
    $toast.style.display = 'none';
    $toast.innerText = '';
  }
};

export const _alert = (txt) => {
  if(!txt) return;

  toast.show(txt);

  setTimeout(() => {
    toast.hide();
  }, 1500);
};
